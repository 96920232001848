import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSpeakers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/speakers`, { params: queryParams })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        fetchSpeaker(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/speaker/${id}/edit`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        addSpeaker(ctx, speakerData) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                
                formData.append("photo", speakerData.photo);
                Object.entries(speakerData).map(item => {
                    if (item[0] !== "photo") {
                        formData.append(item[0], item[1]);
                    }
                });
                
                axios
                .post(`${process.env.VUE_APP_HOST}/api/dashboard/speaker/create`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data'}
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        updateSpeaker (ctx, { id, speakerData }) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
    
                formData.append('names', speakerData.names);
                formData.append('surnames', speakerData.surnames);
                formData.append("photo", speakerData.photo);
                formData.append("changePhoto", speakerData.changePhoto);
                formData.append("initPhoto", speakerData.initPhoto);
                formData.append("email", speakerData.email);
                formData.append("phone", speakerData.phone);
                formData.append("document_number", speakerData.document_number);
                formData.append("cv", speakerData.cv);
        
                axios
                .put(`${process.env.VUE_APP_HOST}/api/dashboard/speaker/${id}/update`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data'}
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        deleteSpeaker (ctx, { id }){
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/speaker/${id}/delete`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        }
    },
}
