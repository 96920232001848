import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useSpeakersList() {
    // Use toast
    const toast = useToast();
    
    const refUserListTable = ref(null);
    
    // Table Handlers
    const tableColumns = [
        { key: 'fullName', sortable: true, label: 'Expositor' },
        { key: 'document_number', sortable: true, label: 'Número de documento' },
        { key: 'email', sortable: true, label: 'Correo' },
        { key: 'phone', sortable: true, label: 'Teléfono' },
        { key: 'n_courses', sortable: true, label: 'Cursos a cargo' },
        { key: 'actions', label: 'Opciones' },
    ];
    
    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);
    
    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        }
    });
    
    const refetchData = () => {
        refUserListTable.value.refresh()
    };
    
    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    });
    
    const fetchSpeakers = (ctx, callback) => {
        store
        .dispatch('app-user/fetchSpeakers', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            const { speakers, total } = response.data;
            callback(speakers);
            totalUsers.value = total;
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error al obtener la lista de expositores',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            });
        })
    };
    
    return {
        fetchSpeakers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        
        refetchData,
    }
}
