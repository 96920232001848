<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>AGREGAR NUEVO EXPOSITOR</strong>
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >

                    <div id="preview" v-if="userData.url">
                        <img class="img-fluid" :src="userData.url" width="70%" alt="image" />
                    </div>

                    <!-- Avatar -->
                    <b-form-group
                        label="Foto"
                        label-for="photo"
                    >
                        <b-form-file
                            id="photo"
                            v-model="userData.photo"
                            :state="userData.statePhoto"
                            @change="onFileChange"
                            accept=".jpg,.jpeg,.png"
                            placeholder="Elija un archivo o suéltelo aquí ..."
                            drop-placeholder="Suelta el archivo aquí ...."
                        />
                    </b-form-group>

                    <!-- Names -->
                    <validation-provider
                        #default="validationContext"
                        name="nombre(s)"
                        rules="required"
                    >
                        <b-form-group
                            label="Nombre(s)"
                            label-for="names"
                        >
                            <b-form-input
                                id="names"
                                v-model="userData.names"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Username -->
                    <validation-provider
                        #default="validationContext"
                        name="apellido(s)"
                        rules="required"
                    >
                        <b-form-group
                            label="Apellido(s)"
                            label-for="surnames"
                        >
                            <b-form-input
                                id="surnames"
                                v-model="userData.surnames"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Document Number -->
                    <validation-provider
                        #default="validationContext"
                        name="número de documento"
                        rules="required"
                    >
                        <b-form-group
                            label="Número de documento"
                            label-for="document_number"
                        >
                            <b-form-input
                                id="document_number"
                                v-model="userData.document_number"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Email -->
                    <validation-provider
                        #default="validationContext"
                        name="correo electrónico"
                        rules="required|email"
                    >
                        <b-form-group
                            label="Correo electrónico"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="userData.email"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Phone -->
                    <validation-provider
                        #default="validationContext"
                        name="teléfono"
                        rules="required"
                    >
                        <b-form-group
                            label="Teléfono"
                            label-for="phone"
                        >
                            <b-form-input
                                id="phone"
                                v-model="userData.phone"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- CV -->
                    <validation-provider
                        #default="validationContext"
                        name="cv"
                        rules="required"
                    >
                        <b-form-group
                            label="CV"
                            label-for="cv"
                        >
                            <b-form-textarea
                                id="cv"
                                v-model="userData.cv"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            AGREGAR
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            CANCELAR
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormFile, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref } from '@vue/composition-api';
    import { required, alphaNum, email } from '@validations';
    import formValidation from '@core/comp-functions/forms/form-validation';
    import Ripple from 'vue-ripple-directive';
    import vSelect from 'vue-select';
    import store from '@/store';

    export default {
        components: {
            BSidebar,
            BForm,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BFormFile,
            BFormInvalidFeedback,
            BButton,
            vSelect,

            // Form Validation
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isAddNewUserSidebarActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isAddNewUserSidebarActive: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                required,
                alphaNum,
                email,
            }
        },
        setup(props, { emit }) {

            // Use toast
            const toast = useToast();

            const blankUserData = {
                names: '',
                surnames: '',
                document_number: '',
                email: '',
                url: null,
                photo: null,
                statePhoto: null,
                phone: '',
                cv: ''
            };

            const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
            const resetuserData = () => {
                userData.value = JSON.parse(JSON.stringify(blankUserData));
            };

            const onSubmit = () => {
                store.dispatch('app-user/addSpeaker', userData.value)
                .then( (response) => {
                    emit('refetch-data');

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });

                    emit('update:is-add-new-user-sidebar-active', false);
                })
                .catch( () => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error al crear el expositor',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    });
                });
            };

            const onFileChange = (e) => {
                const file = e.target.files[0];
                if (file) {
                    userData.value.photo = file;
                    userData.value.url = URL.createObjectURL(file);
                    userData.value.statePhoto = Boolean(userData.value.photo);
                } else {
                    userData.value.photo = null;
                    userData.value.statePhoto = null;
                    userData.value.url = null;
                }
            };

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetuserData);

            return {
                userData,
                onFileChange,
                onSubmit,

                refFormObserver,
                getValidationState,
                resetForm,
            }
        },
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }

    #preview {
        text-align: center;
        margin-bottom: 1rem;
    }

</style>
